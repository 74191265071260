import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

export const CustomButtonComponentStyles = (theme: Theme) =>
  createStyles({
    root: {
      color: "#fff",
      borderRadius: 4,
      height: 44,
      width: 328,
      margin: 8,
      fontFamily: "roboto",
      fontSize: 16,
      boxShadow:
        "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#259a7f",
      },
    },
    autoPublish: {
      color: "#fff",
      borderRadius: 4,
      height: 42,
      width: 'auto',
      margin: 8,
      fontFamily: "roboto",
      fontSize: 15,
      fontWeight: 500,
      boxShadow:
        "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#259a7f",
      },
    },

    small: {
      color: "#fff",
      borderRadius: 4,
      height: 42,
      width: 112,
      margin: 8,
      fontFamily: "roboto",
      fontSize: 15,
      fontWeight: 500,
      boxShadow:
        "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#259a7f",
      },
    },
    fullWidth: {
      color: "#fff",
      borderRadius: 4,
      height: 42,
      width: "100%",
      margin: 8,
      fontFamily: "roboto",
      fontSize: 15,
      fontWeight: 500,
      boxShadow:
        "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#259a7f",
      },
    },
    spinner: {
      margin: 8,
      padding: 4,
    },
  });

export const OtherButtonComponentStyles = makeStyles((theme) => ({
  root: {
    height: "20px !important",
    borderRadius: "10px !important",
    width: 102,
    backgroundColor: theme.palette.primary.main,
    "&:disabled,&:focus,&:active": {
      backgroundColor: theme.palette.primary.main,
    },
    cursor: "pointer !important",
  },
  text: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.3,
    textAlign: "center",
    color: "#fff",
    textTransform: "capitalize",
  },
  disabledText: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: 0.3,
    textAlign: "center",
    color: "#000",
    textTransform: "capitalize",
  },
  disabled: {
    width: 100,
    height: 20,
    borderRadius: 10,
    backgroundColor: "#f57c00",
    "&:disabled,&:focus": {
      backgroundColor: "#eee",
    },
  },
  spinner: {
    margin: 8,
    padding: 4,
  },
  captionUploadButton: {
    color: "#36dcb6",
    fontSize: 14
  },
}));

export const ActionButtonStyle = () =>
  makeStyles((theme) => ({
    root: {
      color: "#fff",
      borderRadius: 4,
      height: 30,
      //maxWidth: 92,
      //width:'auto',
      width: 67,
      // margin: 8,
      paddingLeft: 10,
      paddingRight: 10,
      fontFamily: "roboto",
      fontSize: 13,
      fontWeight: 500,
      boxShadow:
        "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#259a7f",
      },
    },
    reorder: {
      minWidth: "max-content",
      color: "#fff",
      borderRadius: 4,
      height: 30,
      //maxWidth: 92,
      //width:'auto',
      // margin: 8,
      paddingLeft: 10,
      paddingRight: 10,
      fontFamily: "roboto",
      fontSize: 13,
      fontWeight: 500,
      marginRight: 10,
      boxShadow:
        "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#259a7f",
      },
    },
    uploadButton: {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer",
      color: "#fff",
      borderRadius: 4,
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      height: 30,
      //maxWidth: 92,
      //width:'auto',
      width: 92,
      // margin: 8,
      paddingLeft: 10,
      paddingRight: 10,
      fontFamily: "roboto",
      fontSize: 13,
      fontWeight: 500,
      boxShadow:
        "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#259a7f",
      },
    },
    downloadButton: {
      width: 91,
    },
    container: {
      display: "flex",
      justifyContent: "center",
    },
    searchBar: {
      height: 30,
      width: 200,
      backgroundColor: "#efeff4",
    },
    searchBox: {
      backgroundColor: "#efeff4",
      width: 200,
      marginRight: 16,
      borderRadius: 0,
    },
    clearuploadButton: {
      backgroundColor: theme.palette.primary.main,
      cursor: "pointer",
      color: "#fff",
      borderRadius: 4,
      display: "inline-flex",
      flexDirection: "row",
      alignItems: "center",
      height: 30,
      //maxWidth: 92,
      //width:'auto',
      width: 180,
      // margin: 8,
      paddingLeft: 10,
      paddingRight: 10,
      fontFamily: "roboto",
      fontSize: 13,
      fontWeight: 500,
      boxShadow:
        "0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)",
      "&:hover": {
        backgroundColor: "#259a7f",
      },
    },

    spinner: {
      margin: 8,
      padding: 4,
    },
    icon: {
      width: 18,
      height: 18,
      fontSize: 18,
    },
    favIcon: {
      fontSize: 13,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.69",
      letterSpacing: "0.3px",
      color: "var(--white)",
    },
    uploadText: {
      fontFamily: "Roboto",
      fontSize: 13,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.69,
      letterSpacing: 0.3,
      marginRight: 5,
    },
    reOrderText: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: 0.09,
      marginRight: 5,
      color: "#36dcb6",
      cursor: "pointer",
    },
    disableExportText: {
      fontFamily: "Roboto",
      fontSize: 16,
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.5,
      letterSpacing: 0.09,
      marginRight: 5,
      cursor: "pointer",
      padding:"5px",
      height: "32px",
      width: "32px"
    },
    fw400: {
      fontWeight: 400,
      //    cursor: 'inherit',
    },
    fReserColor:{
      color:"#36dcb6!important"
    }
  }))();
