import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "store/store";
import { userApi } from "api/api";
import * as ApiConstants from "constants/apiConstants";
import {
  API_ERRORS_MESSAGES,
  apiResponseEnums,
  MINIMUM_NUMBER_DELETION_CLASS,
  USER_MESSAGES,
} from "constants/enums";
import { regfeshTokenId } from "_pages/loginscreen/authSlice";
import { filter } from 'lodash';
import { setApiResponse } from "Layout/layoutSlice";
import { getCustomAPIErrorMessage, getLanguageFromConfig, handleUnauthorized } from "utils/utilityFunctions";
import { DocumentLibraryData } from "models/documentsLibrary.model";
interface CollectionsSlice {
  collectionsData: any;
  collectionDetails: any;
  isCollectionCreated: boolean;
  isClassesAddedToCollection: boolean;
  isCollectionDeleted: boolean;
  isReorderCollectionClass: boolean;
  success?: boolean|null;
  error: string;
  collectionCreatedStatus: any;
  collectionAddClassesData: any;
  classesAddedToCollectionStatus: any;
  classFilteredData: any;
  copyUrlSuccess: any;
  attachments: DocumentLibraryData | Array<"">;
  collectionLoading: boolean;
  savingReorderedCollections: boolean
  collectionsReorderedSuccessfully: boolean
}

const initialState: CollectionsSlice = {
  collectionsData: [],
  collectionDetails: [],
  isCollectionCreated: false,
  isReorderCollectionClass: false,
  isClassesAddedToCollection: false,
  isCollectionDeleted: false,
  success: null,
  error: "",
  collectionCreatedStatus: "",
  collectionAddClassesData: [],
  classesAddedToCollectionStatus: "",
  classFilteredData: {
    provider: "" as any,
    providerName: "" as any,
    channel: "" as any,
    channelName: "" as any,
    category: "" as any,
    classSubCategoryId: "" as any,
    categoryName: "" as any,
    subCategory: "" as any,
    keyword: "" as any,
    title: "" as any,
    durationSecond: "" as any,
    durationName: "" as any,
    skillLevel: "" as any,
    skillLevelName: "" as any,
    focusArea: "" as any,
    equipment: "" as any,
    intensity: "" as any,
    intensityName: "" as any,
    language: "" as any,
    languageName: "" as any,
    contentType: "" as any,
    reset: false,
  },
  copyUrlSuccess: false,
  attachments: [],
  collectionLoading: false,
  savingReorderedCollections: false,
  collectionsReorderedSuccessfully: false,
};

export const CollectionsSlice = createSlice({
         name: "collection",
         initialState,
         reducers: {
           setCollections: (state, action: PayloadAction<any>) => {
             state.collectionsData = action.payload;
           },
           setReorderCollection: (state, action: PayloadAction<any>) => {
             state.isReorderCollectionClass = action.payload;
           },
           setCollectionsClasses: (state, action: PayloadAction<any>) => {
             state.collectionAddClassesData = action.payload;
           },
           setCollectionsClassesSuccess: (
             state,
             action: PayloadAction<any>
           ) => {
             state.success = action.payload;
           },
           setCollectionDetails: (state, action: PayloadAction<any>) => {
             state.collectionDetails = action.payload;
           },
           setCollectionCreatedStatus: (state, action: PayloadAction<any>) => {
             state.collectionCreatedStatus = action.payload;
           },
           collectionCreated: (state, action: PayloadAction<any>) => {
             state.isCollectionCreated = action.payload;
           },
           classesAddedToCollection: (state, action: PayloadAction<any>) => {
             state.isClassesAddedToCollection = action.payload;
           },
           setClassesAddedToCollectionStatus: (
             state,
             action: PayloadAction<any>
           ) => {
             state.classesAddedToCollectionStatus = action.payload;
           },
           setFilterDataIntoStorage: (state, action: PayloadAction<any>) => {
             state.classFilteredData = action.payload;
           },
           setResetFilterData: (state, action: PayloadAction<any>) => {
             state.classFilteredData = initialState.classFilteredData;
           },
           collectionDeleted: (state, action: PayloadAction<any>) => {
             state.isCollectionDeleted = action.payload;
           },
           errorInCollectionCreation: (state, action: PayloadAction<any>) => {
             state.error = action.payload;
           },
           setCopyURL: (state, action: PayloadAction<any>) => {
             state.copyUrlSuccess = action.payload;
           },
           setSelectedAttachments: (state, action: PayloadAction<any>) => {
             state.attachments = action.payload;
           },
           setCollectionsLoading: (state, action: PayloadAction<any>) => {
             state.collectionLoading = action.payload;
           },
           setSavingReorderedCollections: (
             state,
             action: PayloadAction<boolean>
           ) => {
             state.savingReorderedCollections = action.payload;
           },
           setCollectionsReorderedSuccessfully: (
             state,
             action: PayloadAction<boolean>
           ) => {
             state.collectionsReorderedSuccessfully = action.payload;
           },
         },
       });

export const {
  setCollections,
  collectionCreated,
  collectionDeleted,
  errorInCollectionCreation,
  setReorderCollection,
  setCollectionCreatedStatus,
  setCollectionDetails,
  setCollectionsClasses,
  setCollectionsClassesSuccess,
  classesAddedToCollection,
  setClassesAddedToCollectionStatus,
  setFilterDataIntoStorage,
  setResetFilterData,
  setCopyURL,
  setSelectedAttachments,
  setCollectionsLoading,
  setSavingReorderedCollections,
  setCollectionsReorderedSuccessfully
} = CollectionsSlice.actions;

export const getCollectionDetail = (
  tenantid: any,
  collectionid: any,
  showShimmer: boolean = true
): AppThunk => (dispatch) => {
  if (showShimmer) {
    dispatch(setCollectionDetails({ collectionDetails: null }));
  }

  userApi
    .get(
      `${ApiConstants.getCollectionAPI}${tenantid}/collections/${collectionid}`
    )
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        if (res.data?.results?.length == 0) {
          dispatch(setCollectionDetails({ collectionDetails: [] }));
        
          dispatch(
            setApiResponse({
              status: apiResponseEnums.BAD_REQUEST,
              data: API_ERRORS_MESSAGES.OTHER_ERRORS,
            })
          );
        } else {
          const collectionDetails =  filter(res.data.results[0].collectionItems,handelSorted)
          res.data.results[0].collectionItems = collectionDetails
          dispatch(
            setCollectionDetails({ collectionDetails: res.data.results })
          );
        }
      } else {
      
        const callback = () =>
          dispatch(regfeshTokenId(getCollectionDetail(tenantid, collectionid)));
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: API_ERRORS_MESSAGES.OTHER_ERRORS,
          })
        );
      }
    });
};

export const saveReorderedCollections = (
  tenantId: string,
  collectionIds: any[],
): AppThunk => (dispatch) => {
  const body = {
    TenantId: tenantId,
    CollectionIds: collectionIds,
  };
  dispatch(setSavingReorderedCollections(true));
  userApi
    .post(
      `${ApiConstants.saveReorderedCollectionAPI}`,body
    )
    .then((res: any) => {
        dispatch(setSavingReorderedCollections(false));
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setCollectionsReorderedSuccessfully(true));
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.COLLECTION_REORDERED,
          })
        );
      } else {
        dispatch(setCollectionsReorderedSuccessfully(false));
        const callback = () => handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: API_ERRORS_MESSAGES.OTHER_ERRORS,
          })
        );
      }
    });
};
const handelSorted = (item:any)=>{
  const d1 = new Date();
  const d2 = new Date(item?.publishedDate);
  return d1 >= d2;
  // return true;
}
export const getAllCollectionClasses = (
  tenantid: any,
  showShimmer: boolean = true
): AppThunk => (dispatch) => {
   dispatch(setCollectionsClassesSuccess(false));
   dispatch(setCollectionsClasses({ collectionAddClassesData: [] }));
  userApi
    .get(`${ApiConstants.getAllCollectionClassAPI}${tenantid}/titles`)
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        const collectionAddClassesData =  filter(res.data.results,handelSorted)
        res.data.results = collectionAddClassesData
        dispatch(
          setCollectionsClasses({ collectionAddClassesData: res.data.results })
        );
        if(res?.data?.results?.length > 0)dispatch(setCollectionsClassesSuccess(true));
      } else {
        dispatch(setCollectionsClassesSuccess(false));
        dispatch(setCollectionsClasses({ collectionAddClassesData: [] }));
        const callback = () =>
          dispatch(regfeshTokenId(getAllCollectionClasses(tenantid, false)));
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
   });
};
export const resetCollection = (): AppThunk => (dispatch) => {
  dispatch(setCollections({ collectionsData: null }));
};
export const getAllListCollection = (
  tenantid: any,
  showShimmer: boolean = true
): AppThunk => (dispatch) => {
  if (showShimmer) {
    dispatch(setCollections({ collectionsData: null }));
  } 
  dispatch(setCollectionsLoading(false));
  userApi
    .get(`${ApiConstants.getCollectionListAPI}${tenantid}/collections`)
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(setCollections({ collectionsData: res.data.results }));
        dispatch(setCollectionsLoading(true));
      } else {
        dispatch(setCollectionsLoading(false));
        dispatch(setCollections({ collectionsData: [] }));
        const callback = () =>
          dispatch(regfeshTokenId(getAllListCollection(tenantid)));
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};

export const reorderCollectionsClass = (
  data: any,
  collectionid: any,
  tenantid: any
): AppThunk => (dispatch) => {
  dispatch(errorInCollectionCreation(""));
  userApi
    .put(
      `${ApiConstants.reorderCollectionAPI}${tenantid}/collections/${collectionid}/titles`,
      data
    )
    .then((res: any) => {
      if (res?.status === apiResponseEnums.OKAY) {
        dispatch(getCollectionDetail(tenantid, collectionid, false));
        dispatch(setReorderCollection(true));
      } else {
      
        const callback = () =>
          dispatch(
            regfeshTokenId(
              reorderCollectionsClass(data, collectionid, tenantid)
            )
          );
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};

export const editAndActivateCollection = (
  data: any,
  tenantid: any,
  collectionid: any,
  lastTableConfig: any
): AppThunk => (dispatch) => {
  dispatch(collectionCreated(false));

  let body = {
    Name: data.name,
    Description: data.description,
    IsActive: data.active,
    documentIds: data.documentIds,
  };

  userApi
    .put(
      `${ApiConstants.activateCollectionAPI}${tenantid}/collections/${collectionid}`,
      {
        ...body,
      }
    )
    .then((res: any) => {
      if (
        res?.data &&
        res.data?.result &&
        res.data.result?.code === apiResponseEnums.CREATED
      ) {
        dispatch(collectionCreated(true));
        dispatch(setCollectionCreatedStatus({ status: res.data }));
        dispatch(getCollectionDetail(tenantid, collectionid, false));
        setTimeout(function() {
          dispatch(collectionCreated(false));
        }, 1500);
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
      } else {
      
        const callback = () =>
          dispatch(
            regfeshTokenId(addCollection(data, tenantid, lastTableConfig))
          );
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};

export const setFeaturedCollection = (
  tenantid: any,
  collectionid: any,
  isFeatureOrCOTD: any
): AppThunk => (dispatch) => {
  let body = {};

  if (isFeatureOrCOTD === "featured") {
    body = {
      isFeaturedCollection: true,
    };
  }
  if (isFeatureOrCOTD == "cotd") {
    body = {
      isClassOfDayCollection: true,
    };
  }

  userApi
    .patch(
      `${ApiConstants.setFeaturedCollectionAPI}${tenantid}/collections/${collectionid}`,
      {
        ...body,
      }
    )
    .then((res: any) => {
      if (
        res?.data &&
        res.data?.result &&
        res.data.result?.code === apiResponseEnums.CREATED
      ) {
        dispatch(getAllListCollection(tenantid));
      } else {
        const callback = () =>
          dispatch(
            regfeshTokenId(
              setFeaturedCollection(tenantid, collectionid, isFeatureOrCOTD)
            )
          );
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};
export const addCollection = (
  data: any,
  tenantid: any,
  lastTableConfig: any
): AppThunk => (dispatch) => {
  dispatch(collectionCreated(false));

  let body = {
    name: data.name,
    description: data.description,
    documentIds: data.documentIds,
  };

  userApi
    .post(`${ApiConstants.addCollectionAPI}${tenantid}/collection`, {
      ...body,
    })
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(collectionCreated(true));
        dispatch(setCollectionCreatedStatus({ status: res.data }));
        dispatch(
          getCollectionDetail(tenantid, res?.data?.result?.collectionTag, false)
        );
        setTimeout(function() {
          dispatch(collectionCreated(false));
        }, 1500);
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
      } else {
       
        const callback = () =>
          dispatch(
            regfeshTokenId(addCollection(data, tenantid, lastTableConfig))
          );
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};

export const addCollectionClasses = (
  data: any,
  tenantid: any,
  collectionid: any,
  lastTableConfig: any
): AppThunk => (dispatch) => {
  dispatch(classesAddedToCollection(false));
  userApi
    .post(
      `${ApiConstants.addClassesToCollectionAPI}${tenantid}/collections/${collectionid}/titles`,
      data
    )
    .then((res: any) => {
      if (
        res?.data &&
        res.data?.result &&
        res.data.result?.code === apiResponseEnums.CREATED
      ) {
        dispatch(classesAddedToCollection(true));
        dispatch(getCollectionDetail(tenantid, collectionid));
        dispatch(
          setClassesAddedToCollectionStatus({ status: res.data.result })
        );
        setTimeout(function() {
          dispatch(classesAddedToCollection(false));
        }, 1500);
        dispatch(
          setApiResponse({
            status: apiResponseEnums.OKAY,
            data: USER_MESSAGES.SAVED,
          })
        );
      } else {
        const callback = () =>
          dispatch(
            regfeshTokenId(
              addCollectionClasses(
                data,
                tenantid,
                collectionid,
                lastTableConfig
              )
            )
          );
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};
/**
 * Delete Collection Classes From Collection.
 * If All classes deleted for collection then it's disabled that collections also.
 * @param data Ex.["60540"]
 * @param tenantid  Ex.devangi
 * @param collectionid  Ex.31fc6281-eab6-45d7-9ed4-d34336ca2ad5
 * @returns
 */
export const deleteManageClasses = (
  data: any,
  tenantid: any,
  collectionid: any,
  collectionClassData: any
): AppThunk => (dispatch) => {
  dispatch(collectionDeleted(false));

  let totalClass = collectionClassData?.length;
  let selectedClass = data.length;

  let numberOfDeleteClass: any =
    totalClass && selectedClass ? totalClass - selectedClass : false;

  if (numberOfDeleteClass < MINIMUM_NUMBER_DELETION_CLASS) {
    const lang = getLanguageFromConfig();
    dispatch(
      errorInCollectionCreation(
        "Minimum five classes need to be in a collection."
      )
    );
    setTimeout(function() {
      dispatch(errorInCollectionCreation(""));
    }, 1500);
    dispatch(
      setApiResponse({
        status: apiResponseEnums.BAD_REQUEST,
        data: lang.Collections.collectionDeleteMin,
      })
    );
  } else {
    userApi
      .delete(
        `${ApiConstants.deleteManageClassesAPI}${tenantid}/collections/${collectionid}/titles`,
        {},
        { data: data }
      )
      .then((res: any) => {
        if (
          res?.data &&
          res.data?.result &&
          res.data.result?.code === apiResponseEnums.CREATED
        ) {
          dispatch(collectionDeleted(true));
          dispatch(getCollectionDetail(tenantid, collectionid));
          setTimeout(function() {
            dispatch(collectionDeleted(false));
          }, 1500);
          dispatch(
            setApiResponse({
              status: apiResponseEnums.OKAY,
              data: USER_MESSAGES.DELETE,
            })
          );
        } else {
          const callback = () =>
            dispatch(
              regfeshTokenId(
                deleteManageClasses(
                  data,
                  tenantid,
                  collectionid,
                  collectionClassData
                )
              )
            );
          handleUnauthorized(res.status, callback);
          dispatch(
            setApiResponse({
              status: res.status,
              data: getCustomAPIErrorMessage(res),
            })
          );
        }
      });
  }
};
/**
 * Store data into redux for filter data on manage class
 * @param filterData
 * @param
 * @returns
 */
export const applyFilterDataIntoStorage = (filterData): AppThunk => (
  dispatch
) => {
  dispatch(setFilterDataIntoStorage(filterData));
};
/**
 *
 * @returns reset initial data for filter into classFilteredData
 */
export const resetFilterData = (data): AppThunk => (dispatch) => {
  dispatch(setResetFilterData(data));
};

/**
 *
 * @param tenantid
 * @param collectionid
 * @returns
 */
export const copyURL = (data): AppThunk => (dispatch) => {
  dispatch(setCopyURL(data));
  setTimeout(function() {
    dispatch(setCopyURL(false));
  }, 3000);
  dispatch(setApiResponse({ status: apiResponseEnums.OKAY, data: USER_MESSAGES.COPIED }));
};

/**
 * Delete Collection  From Collection.
 * It's call for delete collections.
 * @param data Ex.["60540"]
 * @param tenantid  Ex.devangi
 * @param collectionid  Ex.31fc6281-eab6-45d7-9ed4-d34336ca2ad5
 * @returns
 */
export const deleteCollections = (
  tenantid: any,
  collectionid: any
): AppThunk => (dispatch) => {
  dispatch(collectionDeleted(false));
  userApi
    .delete(
      `${ApiConstants.deleteCollectionAPI}${tenantid}/collections`,
      {},
      { data: collectionid }
    )
    .then((res: any) => {
      if (
        res?.data &&
        res.data?.result &&
        res.data.result?.code === apiResponseEnums.CREATED
      ) {
        dispatch(collectionDeleted(true));
        dispatch(getAllListCollection(tenantid));
        setTimeout(function() {
          dispatch(collectionDeleted(false));
        }, 1500);
        dispatch(
          setApiResponse({
            status: apiResponseEnums.OKAY,
            data: USER_MESSAGES.DELETE,
          })
        );
      } else {
       
        const callback = () =>
          dispatch(regfeshTokenId(deleteCollections(tenantid, collectionid)));
        handleUnauthorized(res.status, callback);
        dispatch(
          setApiResponse({
            status: res.status,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};


export const addDocumentToCollection = (
  data: any,
  collectionTag,
  resetSelectedCallback
): AppThunk => (dispatch) => {
  userApi
    .post(`${ApiConstants.addDocumentsToCollectionAPI}`, {
      ...data,
    })
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(
          getCollectionDetail(data.tenantId, collectionTag, false)
        );
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
        resetSelectedCallback();
      } else {
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};

export const removeDocumentToCollection = (
  data: any,
  collectionTag,
  resetSelectedCallback
): AppThunk => (dispatch) => {
  userApi
    .delete(`${ApiConstants.removeDocsFromCollectionAPI}`, {}, {
      data,
    })
    .then((res: any) => {
      if (res.status === apiResponseEnums.OKAY) {
        dispatch(getCollectionDetail(data.tenantId, collectionTag, false));
        dispatch(
          setApiResponse({
            status: res.status,
            data: USER_MESSAGES.SAVED,
          })
        );
        resetSelectedCallback();
      } else {
        dispatch(
          setApiResponse({
            status: res.status ?? apiResponseEnums.BAD_REQUEST,
            data: getCustomAPIErrorMessage(res),
          })
        );
      }
    });
};

export const isCollectionCreated = (state: RootState) =>
  state.collections.isCollectionCreated;
export const isCollectionDeleted = (state: RootState) =>
  state.collections.isCollectionDeleted;

export const isClassesAddedToCollection = (state: RootState) =>
  state.collections.isClassesAddedToCollection;

export const collectionsData = (state: RootState) =>
  state.collections.collectionsData.collectionsData;
export const collectionDetails = (state: RootState) =>
  state.collections.collectionDetails.collectionDetails;
export const collectionCreatedStatus = (state: RootState) =>
  state.collections.collectionCreatedStatus.collectionCreatedStatus;

export const collectionAddClassesData = (state: RootState) =>
  state.collections.collectionAddClassesData.collectionAddClassesData;
export const successAddClassesData = (state: RootState) =>
  state.collections.success;

export const error = (state: RootState) => state.collections.error;
export const classesAddedToCollectionStatus = (state: RootState) =>
  state.collections.classesAddedToCollectionStatus;
export const classFilteredData = (state: RootState) =>
  state.collections.classFilteredData;
export const classInitialData = () => initialState.classFilteredData;

export const copyUrlSuccess = (state: RootState) =>
  state.collections.copyUrlSuccess;

export const isReorderCollectionClass = (state: RootState) =>
  state.collections.isReorderCollectionClass;

export const collectionAttachments = (state: RootState) =>
  state.collections.attachments;

export const collectionLoading = (state: RootState) =>
    state.collections.collectionLoading;

export const savingReorderedCollections = (state: RootState) =>
    state.collections.savingReorderedCollections;

export const collectionsReorderedSuccessfully = (state: RootState) =>
         state.collections.collectionsReorderedSuccessfully;

export default CollectionsSlice.reducer;
